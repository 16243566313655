<template>
  <div class="">

    <!--Loading hostaway export spinner-->
    <div v-if="isLoading" class="loading-overlay">
      <div class="loading-spinner">
        <b-spinner></b-spinner>
      </div>
    </div>
    <!--Breadcrumb button-->
    <breadcrumb-buttons hide-settings>
      <template #default>
        <div class="flex-container">
          <b-button id="columns-button" variant="primary" class="btn-icon" v-b-tooltip v-bind:title="$t('Columns')">
            <i class="fa-solid fa-bars"></i>
          </b-button>
          <b-button id="import-real-estates-button" variant="primary" class="btn-icon" v-b-tooltip v-bind:title="$t('importrealestates')" @click="dialogs.importrealestates=true">
            <i class="fa-solid fa-file-arrow-up"></i>
          </b-button>
          <b-button id="import-from-hostaway-button" variant="primary" class="btn-icon" v-b-tooltip v-bind:title="$t('Import from hostaway')">
            <i class="fa-solid fa-cloud-arrow-down"></i>
          </b-button>
          <b-button id="import-from-hostaway-button" variant="primary" class="btn-icon" v-b-tooltip v-bind:title="$t('channelManager')" @click="$refs.channelManagerModal.show()">
            <i class="fa-solid fa-plug"></i>
          </b-button>
        </div>

      </template>
    </breadcrumb-buttons>

    <!--Filters-->
    <b-card no-body>
      <b-badge slot="header" variant="warning" @click="toggleCollapse">
        <!-- You can customize the header content here -->
        {{ filterText }}
      </b-badge>
      <b-collapse v-model="isCollapsed">
        <b-card-header class="pb-50">
          <b-row no-gutters class="w-100">
            <b-col>
              <h5>{{ msg("Filters") }}</h5>
            </b-col>
            <b-col>
              <div class="d-flex justify-content-end">
                <b-link class="mr-1" style="color: inherit" @click="resetFilters">
                  <feather-icon icon="XIcon" size="16"/>
                </b-link>
                <b-link style="color: inherit" @click="refresh()">
                  <feather-icon icon="RefreshCcwIcon" size="16"/>
                </b-link>
              </div>
            </b-col>
          </b-row>
        </b-card-header>
        <b-card-body>
          <b-row>
            <!-- Id -->
            <b-col cols="12" md="4" class="mb-md-0 mb-2">
              <label>{{ msg("Id") }}</label>
              <b-form-input
                  v-model="filter.like.id"
                  class="d-inline-block mr-1"
              />
            </b-col>
            <!--Address-->
            <b-col cols="12" md="4" class="mb-md-0 mb-2">
              <label>{{ msg("Address") }}</label>
              <b-form-input
                  v-model="filter.like.address"
                  class="d-inline-block mr-1"
              />
            </b-col>
            <!-- House number-->
            <b-col cols="12" md="4" class="mb-md-0 mb-2">
              <label>{{ msg("House number") }}</label>
              <b-form-input
                  v-model="filter.like.house"
                  class="d-inline-block mr-1"
              />
            </b-col>
            <!-- Zip-->
            <b-col cols="12" md="4" class="mb-md-0 mb-2">
              <label>{{ msg("Zip") }}</label>
              <b-form-input
                  v-model="filter.like.zip"
                  class="d-inline-block mr-1"
              />
            </b-col>
            <!--City-->
            <b-col cols="12" md="4" class="mb-md-0 mb-2">
              <label>{{ msg("City") }}</label>
              <b-form-input
                  v-model="filter.like.city"
                  class="d-inline-block mr-1"
              />
            </b-col>


            <b-col cols="12" md="6" lg="4">

              <b-form-group :label="msg('Estate agents')" label-for="realEstate-branch">
                <v-select
                    multiple

                    :dir="isRTL ? 'rtl' : 'ltr'"
                    :clearable="true"
                    v-model="filter.in.userIds"
                    :options="allAgents"
                    class="w-100"
                    label="name"
                    :reduce="(branch) => branch.id"

                />
              </b-form-group>
            </b-col>

            <!-- Rooms-->
            <b-col cols="12" md="4" class="mb-md-0 mb-2">
              <label>{{ msg("Rooms") }}</label>
              <b-form-input
                  v-model="filter.eq.realEstateArea.rooms"
                  class="d-inline-block mr-1"
              />
            </b-col>
            <!-- Living space-->
            <b-col cols="12" md="4" class="mb-md-0 mb-2">
              <label>{{ msg("Living space") }}</label>
              <b-form-input
                  v-model="filter.eq.realEstateArea.livingSpace"
                  class="d-inline-block mr-1"
              />
            </b-col>
            <!-- Total space-->
            <b-col cols="12" md="4" class="mb-md-0 mb-2">
              <label>{{ msg("Total space") }}</label>
              <b-form-input
                  v-model="filter.eq.realEstateArea.totalSpace"
                  class="d-inline-block mr-1"
              />
            </b-col>

            <!--Buy/Rent-->
            <b-col cols="12" md="4" class="mb-md-0 mb-2">
              <b-form-group :label="msg('Buy/Rent')" label-for="realEstate-branch">
                <v-select
                    :dir="isRTL ? 'rtl' : 'ltr'"
                    :clearable="true"
                    v-model="filter.eq.marketingType"
                    :options="[{value:{purchase:false,rent:true},label:msg('Rent')},{value:{purchase:true,rent:false},label:msg('Purchase')}]"
                    class="w-100"
                    label="label"
                    :reduce="(branch) => branch.value"
                />
              </b-form-group>
            </b-col>
            <!--Usage-->
            <b-col cols="12" md="4" class="mb-md-0 mb-2">
              <b-form-group :label="msg('Usage')" label-for="realEstate-branch">
                <v-select
                    :dir="isRTL ? 'rtl' : 'ltr'"
                    :clearable="true"
                    v-model="filter.eq.usageType"
                    :options="[
                  {value:{business:false,investment:false,living:true,waz:false},label:msg('Living')},
                  {value:{business:true,investment:false,living:false,waz:false},label:msg('Business')},
                  {value:{business:false,investment:true,living:false,waz:false},label:msg('Investment')}]"
                    class="w-100"
                    label="label"
                    :reduce="(branch) => branch.value"
                />
              </b-form-group>
            </b-col>
            <!--Nuki status-->
            <b-col cols="12" md="4" class="mb-md-0 mb-2">
              <b-form-group :label="msg('Nuki status')" label-for="realEstate-branch">
                <v-select
                    multiple
                    :dir="isRTL ? 'rtl' : 'ltr'"
                    :clearable="true"
                    v-model="filter.in.nukiStatus"
                    :options="[
                      {value:null,label:msg('NODEVICE')},
                      {value:'INSTALLED',label:msg('INSTALLED')},
                      {value:'TOINSTALL',label:msg('TOINSTALL')},
                      {value:'FAULT',label:msg('FAULT')},
                  ]"
                    class="w-100"
                    label="label"
                    :reduce="(branch) => branch.value"

                />
              </b-form-group>
            </b-col>

            <!--featured status-->
            <b-col cols="12" md="4" class="mb-md-0 mb-2">
              <b-form-group :label="msg('Advertised')" label-for="realEstate-branch">
                <v-select
                    :dir="isRTL ? 'rtl' : 'ltr'"
                    :clearable="true"
                    v-model="filter.eq.advertise"
                    :options="[
                                {value:true,label:msg('YES')},
                                {value:false,label:msg('NO')}
                            ]"
                    class="w-100"
                    label="label"
                    :reduce="(branch) => branch.value"

                />
              </b-form-group>
            </b-col>

            <!--featured status-->
            <b-col cols="12" md="4" class="mb-md-0 mb-2">
              <b-form-group :label="msg('Featured status')" label-for="realEstate-branch">
                <v-select
                    multiple
                    :dir="isRTL ? 'rtl' : 'ltr'"
                    :clearable="true"
                    v-model="filter.eq.featureStatus"
                    :options="[
                                {value:{propertyPage:true},label:msg('PROPERTYPAGE')},
                                {value:{searchPage:true},label:msg('SEARCHPAGE')},
                                {value:{homePage:true},label:msg('HOMEPAGE')},
                            ]"
                    class="w-100"
                    label="label"
                    :reduce="(branch) => branch.value"

                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="4" class="mb-md-0 mb-2">
              <label>{{ msg("RentTotal") }} bigger than </label>
              <b-form-input
                  v-model="filter.gte.realEstatePrices.rentTotal"
                  class="d-inline-block mr-1"
              />
            </b-col>

            <!-- Rooms-->
            <b-col cols="12" md="4" class="mb-md-0 mb-2">
              <label>{{ msg("RentNetto") }} bigger than</label>
              <b-form-input
                  v-model="filter.gte.realEstatePrices.rentNetto"
                  class="d-inline-block mr-1"
              />
            </b-col>


            <b-col cols="12" md="4" class="mb-md-0 mb-2">
              <label>{{ msg("RentTotal") }} less than </label>
              <b-form-input
                  v-model="filter.lte.realEstatePrices.rentTotal"
                  class="d-inline-block mr-1"
              />
            </b-col>

            <!-- Rooms-->
            <b-col cols="12" md="4" class="mb-md-0 mb-2">
              <label>{{ msg("RentNetto") }} less than</label>
              <b-form-input
                  v-model="filter.lte.realEstatePrices.rentNetto"
                  class="d-inline-block mr-1"
              />
            </b-col>

            <!-- Rooms-->
            <b-col cols="12" md="4" class="mb-md-0 mb-2">
              <label>{{ msg("HostawayId") }}</label>
              <b-form-input
                  v-model="filter.eq.hostawayId"
                  class="d-inline-block mr-1"
              />
            </b-col>
            <!-- RealEstate type -->
            <b-col cols="12" md="4" lg="4">
              <b-form-group :label="msg('Room category')" label-for="realEstate-type">
                <v-select
                    :dir="isRTL ? 'rtl' : 'ltr'"
                    v-model="filter.eq.imageCategory"
                    :options="[
                  {value:'B2',label:('Basic Zweizimmer')},
                  {value:'D2',label:('Deluxe Zweizimmer')},
                  {value:'B1',label:('Basic Einzimmer')},
                  {value:'D1',label:('Deluxe Einzimmer')},
                  ]"
                    label="label"
                    :reduce="(status) => status.value"
                    class="w-100"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-body>
      </b-collapse>
    </b-card>

    <!--Data-->
    <b-card no-body>
      <div class="card-header" style="overflow: hidden">
        <span class="card-title m-0">{{ msg('Real Estates') }}</span>
        <b-col>
          <div class="d-flex justify-content-end">
            <b-link style="color: inherit" @click="refresh()">
              <feather-icon icon="RefreshCcwIcon" size="16"/>
            </b-link>
          </div>
        </b-col>
      </div>
      <!--Card Content-->
      <b-card-body>
        <div class="">
          <!-- Table Top -->
          <b-row no-gutters class="mb-1">
            <!-- Per Page -->
            <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
              <label>{{ msg('Show') }}</label>
              <v-select
                  v-model="page.amount"
                  @option:selected="refresh"
                  :dir="isRTL ? 'rtl' : 'ltr'"
                  :options="[10, 25, 50, 100,500]"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
              />
              <label>{{ msg('entries') }}</label>
            </b-col>
            <!-- Search -->
            <b-col cols="12" md="6">
              <div class="d-flex align-items-center justify-content-end">
                <b-button class="mr-1" variant="primary" @click="showModal.show=true">{{ msg('copyexpensesnextmonth') }}
                </b-button>
                <span class="mr-1"></span>
                <b-button variant="primary" @click="onRealEstateSelect(0)">
                  <span class="text-nowrap">{{ msg('New real estate') }}</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
        <!--Table-->
        <div v-handy-scroll class="table-responsive">
          <b-table
              ref="realEstate-table"
              striped
              hover
              v-bind:table-class="{'action-active':selectedRealEstates.length > 0}"
              v-bind:items="realEstates"
              v-bind:fields="selectedFields"
              :busy="loading"
              @sort-changed="onSort"
              @row-clicked="onRowClick"
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mx-1"></b-spinner>
                <strong>{{ msg('Loading') }}...</strong>
              </div>
            </template>
            <!--Actions-->
            <template #thead-top="data">
              <tr v-if="selectedRealEstates.length > 0">
                <th>
                  <b-form-checkbox class="d-inline-block" v-bind:checked="isAllRealEstatesSelected"
                                   v-bind:indeterminate="!isAllRealEstatesSelected && selectedRealEstates.length>0"
                                   @change="toggleAllRealEstates"
                  />
                </th>
                <!-- Add this part for the loading sign -->
                <th :colspan="data.columns - 3">
                  <div>
                    <!--                  <b-button variant="success" size="sm" class="mr-1">
                                        <i class="fas fa-stop mx-1"></i>{{ msg('Enable') }}
                                      </b-button>-->
                    <b-button variant="danger" size="sm" class="mr-1" @click="bulkDisableRealEsates">
                      <i class="fas fa-stop mx-1"></i>{{ msg('Disable') }}
                    </b-button>
                    <b-dropdown size="sm" variant="warning" id="dropdown-1" :text="msg('Advertise')" class="mr-1">
                      <b-dropdown-item @click="bulkAdvertiseRealEstates('NONE')">Nicht Inserieren</b-dropdown-item>
                      <b-dropdown-item @click="bulkAdvertiseRealEstates('NORMAL')">Normales Inserat (Kostenlos)
                      </b-dropdown-item>
                      <b-dropdown-item @click="bulkAdvertiseRealEstates('PROPERTYPAGE')">Immobilienseite
                      </b-dropdown-item>
                      <b-dropdown-item @click="bulkAdvertiseRealEstates('SEARCHPAGE')">Suchseite</b-dropdown-item>
                      <b-dropdown-item @click="bulkAdvertiseRealEstates('HOMEPAGE')">Startseite</b-dropdown-item>
<!--
                      <b-dropdown-item @click="bulkAdvertiseRealEstates('EXTERNAL')">EXTERNAL</b-dropdown-item>
-->
                    </b-dropdown>

                    <b-badge variant="secondary" size="sm" class="mr-1">
                      <span>selected:</span> <span>{{ selectedRealEstates.length }}</span>
                    </b-badge>


                  </div>
                </th>
                <th></th>
              </tr>
            </template>
            <!--Head selected-->
            <template #head(select)>
              <b-form-checkbox v-bind:checked="isAllRealEstatesSelected"
                               v-bind:indeterminate="!isAllRealEstatesSelected && selectedRealEstates.length>0"
                               @change="toggleAllRealEstates"
              />
            </template>
            <!--Cell selected-->
            <template #cell(select)="data">
              <b-form-checkbox
                  v-model="selectedRealEstatesIds" :value="realEstates[data.index].id"
              />
            </template>

            <template #cell(contactPerson)="data">
              <span v-if="data.value!=null">{{ data.value.firstname + ' ' + data.value.lastname }}</span>
            </template>

            <template #cell(buildingId)="data">
              <i v-if="data.item.buildingId==null" class="fas fa-warning" style="color: red"></i>
              <i v-else class="fas fa-check" style="color: green"></i>
            </template>
            <!--Cell actions-->
            <template #cell(id)="data">
              <b-button style="width: 95px" variant="outline-primary"
                        size="sm"
                        v-clipboard:copy="data.value"
                        v-clipboard:success="onCopy"
                        v-clipboard:error="onError"
                        class="d-block">
                <i class="fas fa-copy"> </i> ID
              </b-button>

              <b-button variant="outline-info" style="width: 95px;white-space: nowrap" size="sm"
                        @click="openModal(data.value)"
                        class="btn  d-block mt-1 mb-1">
                {{ msg('Duplicate') }}
              </b-button>

              <b-button style="width: 95px" variant="outline-danger" size="sm"
                        @click="info(data.item, data.index, $event.target)"
                        class="btn  d-block mt-1 mb-1">
                {{ msg('Disable') }}
              </b-button>

              <b-button variant="outline-warning" size="sm" @click="infoFeature(data.item, data.index, $event.target)"
                        class="btn  d-block mt-1 mb-1">
                {{ msg('Advertise') }}
              </b-button>


            </template>
            <template #cell(updatedDate)="data">
              {{ $moment(data.value, 'YYYY-MM-DD HH:mm').format("DD/MM/YY HH:mm") }}
            </template>
            <template #cell(realEstateTreasure.keyCode)="data">
              <span :title="data.value" class="d-inline-block"
                    style="overflow:hidden; white-space: nowrap; text-overflow: ellipsis; max-width: 100px">{{
                  data.value
                }}</span>
            </template>
            <template #cell(hostawayId)="data">

              <b-badge variant="primary" v-if="(data.item.rentalId==null || data.item.rentalId<1)&& data.value==null ">
                {{ msg('Empty') }}
              </b-badge>
              <b-badge variant="danger" v-else>{{ data.item.hostawayId!=null ? data.item.hostawayId : 'Empty' }} / {{ data.item.rentalId!=null && data.item.rentalId>0?data.item.rentalId:'Empty' }}</b-badge>

            </template>
            <template #cell(address)="data">
              <div>
                <div style="white-space: nowrap">
                  <span class="d-inline-block">{{ getFullAddress(data.item) }}</span>
                </div>
                <div style="margin-left: 5px">
                  <strong>{{ msg('Zip') }}: </strong>{{ data.item.zip }}
                </div>
              </div>
            </template>
            <template #cell(attachments)="data">
              <b-img
                  v-bind=" { blank: (data.value == null || data.value[0] == null), blankColor: '#777', width: 50, height: 50,
                                src:(data.value != null && data.value[0] != null ? mergeSrcUrl(getRealEstatesWebSite,data.value[0]) : null)  }"
                  blank-color="#475F7B"
                  rounded
                  alt="Rounded image"
                  class="d-inline-block m-1"
              />
            </template>
            <template #cell(realEstatePrices)="data">
              <div>
                {{ getPrices(data) }}
              </div>
            </template>

            <template #cell(realEstateTreasure)="data">
              <div v-if="data.value !== null" class="btn-group">

                <b-button v-if="data.value.bookingLink != null && data.value.bookingLink.trim()!==''" class="btn"
                          style="background-color: rgba(49,121,231,0.73)!important;" size="sm"
                          v-clipboard:copy="data.value.bookingLink"
                          v-clipboard:success="onLinkCopy"
                          v-clipboard:error="onError"

                          v-b-tooltip.hover
                          title="Booking"
                >
                  <i>B</i>
                </b-button>
                <b-button v-if="data.value.airbnbLink != null&& data.value.airbnbLink.trim()!==''" class="btn"
                          style="background-color: rgba(255,82,88,0.79)!important;" size="sm"
                          v-clipboard:copy="data.value.airbnbLink"
                          v-clipboard:success="onLinkCopy"
                          v-clipboard:error="onError"

                          v-b-tooltip.hover
                          title="Airbnb"
                >
                  <i>A</i>
                </b-button>
                <b-button v-if="data.value.expediaLink != null" class="btn btn-warning" size="sm"
                          v-clipboard:copy="data.value.expediaLink&& data.value.expediaLink.trim()!==''"
                          v-clipboard:success="onLinkCopy"
                          v-clipboard:error="onError"

                          v-b-tooltip.hover
                          title="Expedia"
                >
                  <i>E</i>
                </b-button>
                <b-button v-if="data.value.treasureLink != null"
                          style="background-color: rgba(199,166,128,0.71)!important;" class="btn" size="sm"
                          v-clipboard:copy="data.value.treasureLink&& data.value.treasureLink.trim()!==''"
                          v-clipboard:success="onLinkCopy"
                          v-clipboard:error="onError"
                          v-b-tooltip.hover
                          title="Treasure Home"
                >
                  <i>T</i>
                </b-button>

                <b-button v-if="data.value.agodaLink != null" style="background-color: #2ecc71!important;" class="btn"
                          size="sm"
                          v-clipboard:copy="data.value.agodaLink&& data.value.agodaLink.trim()!==''"
                          v-clipboard:success="onLinkCopy"
                          v-clipboard:error="onError"
                          v-b-tooltip.hover
                          title="Agoda"
                >
                  <i>A</i>
                </b-button>
                <b-button v-if="data.value.vrboLink != null&& data.value.vrboLink.trim()!==''" class="btn"
                          style="background-color: #bfddea!important;"
                          size="sm"
                          v-clipboard:copy="data.value.vrboLink"
                          v-clipboard:success="onLinkCopy"
                          v-clipboard:error="onError"
                          v-b-tooltip.hover
                          title="Vrbo"
                >
                  <i>V</i>
                </b-button>
              </div>

            </template>


            <!--Cell status-->
            <template #cell(status)="data" class="p-0">
              <b-badge class="mb-1 d-block" :variant="statusVariants(data.value)">Status: {{ data.value }}</b-badge>

              <b-badge class="mb-1 d-block" :variant="data.item.nukiStatus!=null?'primary':'danger'">Nuki:
                {{ data.item.nukiStatus != null ? msg(data.item.nukiStatus) : "Kein Gerät" }}
              </b-badge>

              <b-badge class="d-block"
                       :variant="data.item.advertise !=null && data.item.advertise===true ?'success':'danger'">
                Inserat: {{ getFeaturedStatus(data.item) }}
              </b-badge>


            </template>

            <template #cell(todoList)="data">
              <b-badge
                  class="mb-1" variant="primary">{{ countDoneItems(data.item.todoList) }}
              </b-badge>
            </template>

            <template #cell(userIds)="data">
              <div v-for="userId in data.value" v-bind:key="userId">
                <b-badge class="mb-1" variant="info">{{ getUserFullName(userId) }}</b-badge>
              </div>
            </template>

          </b-table>
        </div>
        <b-modal ref="myModal" @ok="confirmAndCopy" size="sm"
                 :ok-disabled="duplicate.name==null || duplicate.apt==null ||duplicate.rooms==null ||duplicate.area==null ||duplicate.peoples==null">
          <template #modal-title>
            {{ msg('duplicateestate') }}
          </template>

          <b-col  cols="12" md="12" lg="12">
            <b-form-group :label="msg('name')" label-for="realEstate-name">
              <b-form-input v-model="duplicate.name" name="check-button">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col  cols="12" md="12" lg="12">
            <b-form-group :label="msg('apt')" label-for="realEstate-name">
              <b-form-input v-model="duplicate.apt" >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col  cols="12" md="12" lg="12">
            <b-form-group :label="msg('People capacity')" label-for="realEstate-name">
              <b-form-input v-model="duplicate.peoples" name="check-button" type="number">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col  cols="12" md="12" lg="12">
            <b-form-group :label="msg('rooms')" label-for="realEstate-name">
              <b-form-input v-model="duplicate.rooms" name="check-button" type="number">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col  cols="12" md="12" lg="12">
            <b-form-group :label="msg('area')" label-for="realEstate-name">
              <b-form-input v-model="duplicate.area" name="check-button" type="number">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-modal>
        <b-modal :id="infoModal.id" :title="msg('Confirmation')" @ok="archiveRealEstateById(infoModal.propertyId)"
                 ok-variant="danger" @hide="resetInfoModal">
          <p class="my-4">Are you sure you want to ARCHIVE this real estate? {{ '(' + addressName + ')' }}</p>
        </b-modal>

        <b-modal size="lg" :id="featureInfoModal.id"
                 :title="msg('Confirmation')"
                 :ok-title="featureInfoModal.type==='NONE'? msg('Deactivate'):
                 (featureInfoModal.type==='NORMAL'? msg('Activate for free') : msg('Chargeable activation'))"
                 @ok="featureRealEstateById(featureInfoModal.propertyId,featureInfoModal.type)"
                 ok-variant="primary" @hide="resetFeatureInfoModal">

          <div class="w-100" style="text-align: center">
            <p class="mb-3" style="color:white;font-size: x-large;font-weight: bold;">Inserat Optionen</p>
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-row class="features-group">
                <b-col cols="6" class="mb-1">
                  <b-form-radio name="features-group" :aria-describedby="ariaDescribedby"
                                class="w-100"
                                value="NONE" v-model="featureInfoModal.type"
                                button-variant="outline-danger" button>
                    <div class="text-danger">
                      <p class="mb-0"><i class="far fa-stop-circle"></i></p>
                      <p>Nicht Inserieren</p>
                    </div>
                  </b-form-radio>
                </b-col>

                <b-col cols="6" class="mb-1">
                  <b-form-radio name="features-group"
                                :aria-describedby="ariaDescribedby"
                                value="NORMAL"
                                v-model="featureInfoModal.type"
                                button-variant="outline-primary" button>
                    <div>
                      <p class="mb-0"><i class="far fa-play-circle"></i></p>
                      <p>Normales Inserieren (Kostenlos)</p>
                    </div>
                  </b-form-radio>
                </b-col>


                <b-col cols="6" class="mb-1">
                  <b-form-radio name="features-group"
                                :aria-describedby="ariaDescribedby"
                                v-model="featureInfoModal.type"
                                value="PROPERTYPAGE"
                                button-variant="outline-success" button>
                    <div class="text-success">
                      <p class="mb-0"><i class="fas fa-tags"></i></p>
                      <p class="mb-0">Ausgewählte Immobilien- Immobilienseite</p>
                      <p class="mb-0 font-weight-bold"><strong>€11/30 Tage</strong></p>
                    </div>
                  </b-form-radio>
                </b-col>

                <b-col cols="6" class="mb-1">
                  <b-form-radio name="features-group"
                                :aria-describedby="ariaDescribedby"
                                v-model="featureInfoModal.type"
                                value="SEARCHPAGE" button-variant="outline-success" button>
                    <div class="text-success">

                      <p class="mb-0"><i class="fas fa-coins"></i></p>
                      <p class="mb-0"> Ausgewählte Immobilien- Suchseite</p>
                      <p class="mb-0 font-weight-bold"><strong>€17/30 Tage</strong></p>

                    </div>
                  </b-form-radio>
                </b-col>

                <b-col cols="12" class="mb-1">
                  <b-form-radio name="features-group" value="HOMEPAGE" :aria-describedby="ariaDescribedby"
                                v-model="featureInfoModal.type"
                                button-variant="outline-success" button>
                    <div class="text-success">

                      <p class="mb-0"><i class="far fa-gem"></i></p>
                      <p class="mb-0"> Ausgewählte Immobilien- Startseite</p>
                      <p class="mb-0 font-weight-bold"><strong>€60/30 Tage</strong></p>

                    </div>
                  </b-form-radio>
                </b-col>
              </b-row>


            </b-form-group>


          </div>
        </b-modal>


        <!--Table Paginator-->
        <div class="text-center w-100">
          <div class="d-inline-block">
            <b-pagination
                v-model="page.currentPage"
                :total-rows="page.total"
                :per-page="page.amount"
                first-number
                last-number
                @input="refresh"
            />
          </div>
          <div class="d-inline-block px-2"><strong>{{ msg('Total') }}</strong> {{ page.total }}</div>
        </div>
      </b-card-body>
    </b-card>

    <b-modal ref="modalRef" size="lg" :title="msg('Listings from hostaway that arent currently in your system')"
             :hide-footer="true">
      <div v-if="fetchedListingMap==null || fetchedListingMap.length === 0">Loading...</div>
      <template v-else>
        <client-picker class="mb-2" v-model="ownerId" is-owner/>

        <v-select
            v-if="buildings!=null && buildings.length>0"
            placeholder="Building"
            :dir="isRTL ? 'rtl' : 'ltr'"
            v-model="importBuildingId"
            :options="buildings"
            class="w-100 mb-2"
            label="address"
            :reduce="(building) => building.id"
        />
        <div class="mb-2" v-for="(value, key) in fetchedListingMap" :key="key">
          <b-button :disabled="importBuildingId ==null || ownerId==null" variant="success" size="sm"
                    @click="fetchListingFromHostaway(key)">Import
          </b-button>
          <span class="mx-1">{{ value }}</span>
        </div>
      </template>
    </b-modal>

    <b-modal no-fade v-model="dialogs.realEstate" size="xl" ref="realEstate-modal" id="realEstate-modal"
             :hide-footer="true" no-close-on-backdrop centered>
      <realEstate-panel ref="realEstatePanel" @submitted="onSubmitted" :all-buildings="buildings"/>
      <div class="text-center" style="justify-content:center">
        <b-button variant="outline-primary" class="btn-dialog" @click="submitRealEstate">
          {{ msg('Save') }}
        </b-button>
      </div>
    </b-modal>

    <!--Excel building-->
    <b-modal v-model="dialogs.importrealestates" size="xl" @show="dismissAllPopovers" centered hide-footer no-close-on-backdrop>
      <template #modal-title>{{ msg("importrealestatesoptional-(selectbuildingfromthelistor/andhostawayexport)") }}</template>
      <div>
        <b-row class="mb-1 ">

          <b-col cols="4" class="d-flex align-items-center justify-content-start">
            <b-button class="text-nowrap" variant="success" v-b-tooltip
                      :title="msg('Click here to download the excel template')"
                      @click="downloadExcelTemplateRealEstates">
              <i class="far fa-file-excel mr-1"></i>
              <span>Excel template</span>
            </b-button>
          </b-col>


          <b-col cols="4"  class="d-flex align-items-center justify-content-start mr-1 ">
            <v-select
                :dir="isRTL ? 'rtl' : 'ltr'"
                v-model="excelBuid"
                placeholder="Branch"
                :options="allBranches"
                class="w-100"
                label="name"
                :reduce="(branch) => branch.id"
                :clearable="false"

            />
          </b-col>
          <b-col v-if="buildings!=null && buildings.length>0" cols="4" md="3"
                 class="d-flex align-items-center justify-content-start mr-1 ">
            <v-select
                placeholder="Building (Optional)"
                :dir="isRTL ? 'rtl' : 'ltr'"
                v-model="buildingId"
                :options="buildings"
                class="w-100"
                label="address"
                :reduce="(building) => building.id"
            />
          </b-col>
        </b-row>
        <b-row v-if="excelBuid!=null">
          <b-col cols="4" class="mt-2 align-items-center  justify-content-end ">
            <div>
              <b-form-file
                  v-model="file"
                  :placeholder="msg('Drop your client excel file here...')"
                  :drop-placeholder="msg('Drop file here...')"
              />
            </div>

          </b-col>
          <b-col cols="1" class="d-flex align-items-center mt-1">
            <b-button :disabled="file==null" variant="danger" @click="resetFile">
              <span class="text-nowrap">{{ msg('Reset file') }}</span>
            </b-button>
          </b-col>
        </b-row>
      </div>

    </b-modal>

    <!--Connect to channel-->
    <channel-manager-modal ref="channelManagerModal"/>

    <b-modal v-model="showModal.show" :title="msg('Confirmation')" @ok="copyToNextExpenses"
             ok-variant="danger" :cancel-title="msg('cancel')" @hide="showModal.show=false">
      <p class="my-4">{{ msg('confirmcopyexpenses') }}</p>
    </b-modal>

    <!--Columns popover-->
    <b-popover size="xl" target="columns-button" triggers="click" placement="bottom" @show="dismissAllPopovers">
      <template #title>{{ msg("Columns") }}</template>
      <div style="max-width: 800px">
        <div v-if="fields!=null&& fields.length>0">
          <b-form-checkbox class="custom-control-inline mb-1" v-for="field in fields" v-model="field.active"
                           v-bind:key="field.key">{{ field.label }}
          </b-form-checkbox>
        </div>
      </div>
    </b-popover>


    <!--Fetch from hostaway popover-->
    <b-popover size="xl" target="import-from-hostaway-button" triggers="click" placement="bottom" @show="dismissAllPopovers">
      <template #title>{{ msg("Import from hostaway") }}</template>
      <div style="max-width: 800px; min-width: 500px">
        <div class="mb-1">
          <v-select
              :dir="isRTL ? 'rtl' : 'ltr'"
              v-model="hostawayBuid"
              placeholder="Branch"
              :options="allBranches"
              class="w-100"
              label="name"
              :reduce="(branch) => branch.id"
              :clearable="false"

          />
        </div>
        <b-button block :disabled="hostawayBuid == null || fetchListLoading" class="text-nowrap" variant="warning"
                  v-b-tooltip @click="fetchListFromHostaway">
          <i v-if="fetchListLoading" class="fas fa-spinner fa-spin mr-1"></i> <!-- Spinner -->
          <i v-else class="fas fa-list mr-1"></i> <!-- Icon -->
          <span v-if="fetchListLoading">Loading...</span> <!-- Loading text -->
          <span v-else>Fetch list</span> <!-- Default text -->
        </b-button>

      </div>
    </b-popover>


  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import RealEstatePanel from './realEstatePanel'
import PlatformLogo from "@/components/widgets/PlatformLogo.vue";
import mixinFilter from "@/mixins/mixinFilter";
import ClientPicker from "@/components/components/picker/clientPicker.vue";
import BreadcrumbButtons from "@/components/widgets/breadcrumbButtons.vue";
import ChannelManagerModal from "@/components/modals/channelManagerModal.vue";

const realEstateFilter = () => ({
  like: {search: '', name: "", shortDescription: ""},
  eq: {type: "", realEstateArea: {}, featureStatus: null},
  lte: {realEstatePrices: {}},
  gte: {realEstatePrices: {}},
  in: {}
})

const buildingFilter = () => ({
  like: {},
  eq: {},
  lte: {},
  gte: {},
  in: {}
})
export default {
  name: 'RealEstate',
  mixins: [mixinFilter],
  components: {
    ChannelManagerModal,
    BreadcrumbButtons,
    ClientPicker,
    // eslint-disable-next-line vue/no-unused-components
    PlatformLogo,
    RealEstatePanel
  },
  data() {
    return {
      loading: true,
      buildingFilter: buildingFilter(),
      amount: 10,
      ownerId: null,
      importBuildingId: null,
      buid: null,
      platformLogos: {
        'Vrbo': 'https://treasurehome.at/uploads/images/vrbo.png',
        'Booking': 'https://treasurehome.at/uploads/images/booking.png',
        'Airbnb': 'https://treasurehome.at/uploads/images/airbnb.png',
        'Treasure Home': 'https://treasurehome.at/uploads/images/treasurehome.png',
        'Agoda': 'https://treasurehome.at/uploads/images/agoda.png',
        'Expedia': 'https://treasurehome.at/uploads/images/expedia.png',
      },
      isCollapsed: false,
      isLoading: false,
      showModal: {
        id: 'show-modal',
        title: '',
        content: '',
        show: false,
        expenseId: null,
      },
      realEstates: [],
      buildings: [],
      buildingId: null,
      hostawayBuid: null,
      fetchedListingMap: null,
      fetchListLoading: false,
      excelBuid: null,
      excelUrl: "",
      duplicate: {
        name: null,
        apt: null,
        peoples: null,
        area: null,
        rooms: null,

      },

      file: null,
      selectedRealEstates: [],
      fields: [],
      filter: realEstateFilter(),
      sort: {
        by: 'id',
        desc: true,
        direction: 'desc'
      },

      page: {
        amount: 10,
        currentPage: 1,
        total: 0
      },
      modalContent: '',
      dialogs: {realEstate: false,importrealestates: false},
      addressName: null,
      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      },
      featureInfoModal: {
        id: 'feature-modal',
        type: null,
        title: '',
        content: ''
      },
      channelInfoModal: {
        id: 'channel-modal',
        channelMap: null,
        type: null,
        title: '',
        content: ''
      },
      //Listeners
      listeners: {
        refresh: () => {
        }
      }
    }
  },
  watch: {
    file: {
      handler() {
        this.uploadFile()
      }
    },
    filter: {
      deep: true,
      handler() {
        this.refresh()
      }
    },
    fields: {
      deep: true,
      handler() {
        localStorage["real-estates-fields"] = JSON.stringify(this.fields);
      }
    },
    selectedRealEstates: {
      handler() {
        this.$emit("selectedRealEstates", [...this.selectedRealEstates])
      }
    }
  },
  computed: {
    ...mapGetters('data', ['getOrganizationWebSite', 'getRealEstatesWebSite']),
    ...mapGetters('data', ['getUserFullName', 'getUserAcronyms']),
    ...mapGetters('data', ['getAllUsers', 'getUsersInTeam', 'getUserRole', 'getRoles', 'getRoleName']),
    ...mapGetters('realEstate', ['allStatus', 'statusVariants', 'getPrices', 'getFeaturedStatus', "getFullAddress"]),
    ...mapGetters('translator', ['msg']),
    ...mapGetters('data', ['getAllBranches']),
    filterText() {
      if (!this.isCollapsed) {
        return this.msg('Show filter')
      } else {
        return this.msg('Hide filter')
      }
    },
    allBranches() {
      return Object.values(this.getAllBranches)
    },

    selectedFields() {
      let fields = this.fields.filter(f => f.active);
      return (fields.length > 0) ? fields : this.fields;
    },
    selectedRealEstatesIds: {
      get() {
        return this.selectedRealEstates.map(p => p.id);
      },
      set(selectedRealEstatesIds) {
        let realEstates = this.realEstates.toObject("id");
        this.selectedRealEstates = selectedRealEstatesIds.map(pid => realEstates[pid]);
      }
    },
    isAllRealEstatesSelected() {
      let ids = this.selectedRealEstates.map(p => p.id);
      return this.realEstates.filter(p => !ids.includes(p.id)).length === 0;
    },
    allRealEstateStatus() {
      return this.allStatus.map(status => ({
        value: status.value,
        text: this.msg(status.text)
      }))
    },
    allAgents() {

      return this.getAllUsers
          .filter(us => us.roleId === 18)
          .map(us => ({
            id: us.id,
            name: (us.firstname + ' ' + us.lastname)
          }))
    },
    allRealEstates() {
      return this.realEstates
    }
  },
  created() {
    let $this = this;
    this.setFields();
    this.filter = this.loadCachedFilters(realEstateFilter);
    this.refresh()
    this.$root.$on('crm::branch-updated', (this.listeners.refresh = () => {
      $this.refresh()
    }))
    this.$root.$on('crm::lang-updated', this.setFields)
  },
  beforeDestroy() {
    this.$root.$off('crm::branch-updated', this.listeners.refresh)
    this.$root.$off('crm::lang-updated', this.setFields)
  },
  methods: {
    ...mapActions('realEstate', ['copyToNextExpensesRealEstate', 'getExcelTemplateUrl']),
    ...mapActions('realEstate', ['getAllRealEstates', 'archiveRealEstate', 'copyLinkSuccess', 'channelExport', 'featureRealEstate', 'deFeatureRealEstate', 'copySuccess']),
    ...mapActions('realEstate', ['getAllDisabledRealEstates', 'activateRealEstate', 'bulkDisable', 'copyRealEstate',
      'fetchRealEstateFromHostaway', 'fetchAllRealEstateFromHostaway', 'bulkAdvertise', 'justimmoUpdate']),
    ...mapActions('building', ['getAllBuildings']),
    ...mapActions('realEstate', {_uploadExcel: 'uploadExcel'}),
    fetchListFromHostaway() {
      this.fetchListLoading = true;
      this.fetchAllRealEstateFromHostaway({buid: this.hostawayBuid})
          .then((ans) => {
            this.fetchedListingMap = ans;
            this.$refs.modalRef.show();
            this.fetchListLoading = false;
          })
    },
    fetchListingFromHostaway(hostawayId) {
      this.fetchListLoading = true;
      this.fetchRealEstateFromHostaway({
        buid: this.hostawayBuid, hostawayId: hostawayId,
        ownerId: this.ownerId, buildingId: this.importBuildingId
      })
          .then((ans) => {
            this.fetchListFromHostaway();

          })
    },

    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    },
    uploadFile() {
      let $this = this
      if (this.file == null)
        return;

      let paramMap = {"buildingId": this.buildingId, "buid": this.excelBuid}
      this._uploadExcel({file: this.file, params: paramMap}).finally(() => $this.refresh());

    },
    downloadExcelTemplateRealEstates() {
      console.log("excel template url :", this.excelUrl)
      window.open(this.excelUrl, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
    },
    resetFile() {
      this.file = null;
    },
    platformLogoUrl(platformName) {
      return this.platformLogos[platformName];

    },
    copyToClipboard(link) {
      const textArea = document.createElement('textarea');
      textArea.value = link;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
      // You can also display a success message here or handle the copied link as needed.
    },

    bulkDisableRealEsates() {
      let $this = this;
      this.bulkDisable(this.selectedRealEstates)
          .then(() => {
            $this.refresh()
          })

    },
    bulkAdvertiseRealEstates(type) {
      let $this = this;
     // this.isLoading = true; // Set loading state to true

      this.bulkAdvertise({ids: this.selectedRealEstates, featureType: type})
          .then(() => {
            $this.refresh();
          })
          .finally(() => {
            $this.isLoading = false; // Reset loading state when API call completes (success or error)
          });
    },

    toggleAllRealEstates(estate) {
      if (estate)
        this.selectedRealEstates = [...this.realEstates];
      else
        this.selectedRealEstates = [];
    },
    archiveRealEstateById(realId) {
      let $this = this;
      this.archiveRealEstate(realId)
          .then(() => {
            $this.refresh()
          })
    },
    featureRealEstateById(realId, type) {
      let $this = this;
      this.featureRealEstate({id: realId, featureType: type})
          .then(() => {
            $this.refresh()
          })
    },
    channelExportRealEstateById(realId, type) {
      let $this = this;
      this.channelExport({id: realId, featureType: type})
          .then(() => {

            $this.refresh()
            $this.resetChannelInfoModal()
          })
    },

    onCopy: function (e) {
      this.copySuccess(e.text)
    },
    onLinkCopy: function (e) {
      console.log(e.text)
      this.copyLinkSuccess(e.text)
    },
    onError: function (e) {
      alert('Failed to copy the text to the clipboard')
    },

    setFields() {
      let $this = this
      let fields = [
        {
          key: 'select',
          label: $this.msg('Bulk select'),
          sortable: false,
          active: true,
        },
        {
          key: 'id',
          label: $this.msg('Actions'),
          sortable: true,
          isActive: false,
          active: true,
        },
        {
          key: 'realEstateTreasure',
          label: $this.msg('Platform'),
          sortable: true,
          active: true,
        },
        {
          key: 'name',
          label: $this.msg('Name'),
          sortable: true,
          active: true,
        },
        {
          key: 'address',
          label: $this.msg('Address'),
          sortable: true,
          active: true,
        },
        {
          key: 'updatedDate',
          label: $this.msg('Updated at'),
          sortable: true,
          active: true,
        },


        {
          key: 'attachments',
          label: $this.msg('Main image'),
          sortable: true,
          active: true,
        },
        {
          key: 'hostawayId',
          label: $this.msg('ExternalId'),
          sortable: true,
          active: true,
        },
        {
          key: 'buildingId',
          label: $this.msg('Building'),
          sortable: false,
          active: true,
        },

        {
          key: 'contactPerson',
          label: $this.msg('Owner'),
          sortable: true,
          active: true,
        },
        {
          key: 'apt',
          label: $this.msg('Apt'),
          sortable: true,
          active: true,
        },

        {
          key: 'status',
          label: $this.msg('status'),
          sortable: true,
          active: true,
        },
        {
          key: 'imageCategory',
          label: $this.msg('Room category'),
          sortable: true,
          active: true,
        },

        {
          key: 'todoList',
          label: $this.msg('Todos'),
          sortable: true,
          active: true,
        },

        {
          key: 'userIds',
          label: $this.msg('Agent/s'),
          sortable: true,
          active: true,
        },
        {
          key: 'realEstatePrices',
          label: $this.msg('Price'),
          sortable: false,
          active: true,
        },
        {
          key: 'realEstateArea.livingSpace',
          label: $this.msg('Living space'),
          sortable: false,
          active: true,
        },
        {
          key: 'realEstateArea.totalSpace',
          label: $this.msg('Total space'),
          sortable: false,
          active: true,
        },
        {
          key: 'realEstateArea.rooms',
          label: $this.msg('Rooms'),
          sortable: true,
          active: true,
        },

        {
          key: 'realEstateTreasure.keyCode',
          label: $this.msg('KeyCode'),
          sortable: true,
          active: true,
        },

        {
          key: 'realEstateTreasure.wifiUsername',
          label: $this.msg('wifiUsername'),
          sortable: true,
          active: true,
        },

        {
          key: 'realEstateTreasure.wifiPassword',
          label: $this.msg('wifiPassword'),
          sortable: true,
          active: true,
        },

        /* {
           key: 'actions',
           label: $this.msg('Actions'),
           sortable: true
         },*/
      ]
      this.fields = fields;
      try {
        let cachedFields = localStorage["real-estates-fields"];
        if (cachedFields != null) {
          let cachedFieldArr = JSON.parse(cachedFields);
          let cachedFieldsMap = cachedFieldArr.toObject("key");
          fields.forEach(field => field.active = cachedFieldsMap[field.key].active != null ? cachedFieldsMap[field.key].active : true);
        }
      } catch (e) {
        localStorage.setItem("real-estates-fields", null);
      }
    },
    updateFromjustimmo() {
      let $this = this;
      if (this.buid == null) return
      this.justimmoUpdate({buid: this.buid})
          .then(() => {
            $this.refresh()
          })
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.propertyId = item.id;
      this.addressName = item.address != null ? item.address : "Die Addresse ist leer";
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    infoFeature(item, index, button) {
      this.featureInfoModal.type = null;
      this.featureInfoModal.title = `Row index: ${index}`
      this.featureInfoModal.propertyId = item.id;
      this.addressName = item.address != null ? item.address : "Die Addresse ist leer";
      this.$root.$emit('bv::show::modal', this.featureInfoModal.id, button)
    },
    infoChannel(item, index, button) {
      this.channelInfoModal.type = null;
      this.channelInfoModal.title = `Row index: ${index}`
      this.channelInfoModal.propertyId = item.id;
      this.channelInfoModal.channelMap = item.channelExport;
      this.$root.$emit('bv::show::modal', this.channelInfoModal.id, button)
    },
    copyId(id) {
      console.log("the id copied was: ", id)
    },
    openModal(id) {
      this.selectedRealEstateId = id;
      console.log("selectedRealEstateId: ", this.selectedRealEstateId)
      this.$refs.myModal.show();
    },
    dismissAllPopovers() {
      this.$root.$emit('bv::hide::popover')
    },
    // Method to confirm and call the copyRealEstate function
    confirmAndCopy() {
      let $this = this
      this.copyRealEstate({id: this.selectedRealEstateId, duplicate: this.duplicate})
          .then((id) => {
            $this.selectedRealEstateId = null;
            $this.refresh()
            window.open(`/real_estate/deleted_real_estate`, '_blank');

          })
      // After confirmation, close the modal
      this.$refs.myModal.hide();
    },
    resetInfoModal() {
      this.$root.$emit('bv::hide::modal', this.infoModal.id)
    },
    resetFeatureInfoModal() {
      this.featureInfoModal.type = null;
      this.$root.$emit('bv::hide::modal', this.featureInfoModal.id)
    },
    resetChannelInfoModal() {
      this.channelInfoModal.type = null;
      this.$root.$emit('bv::hide::modal', this.channelInfoModal.id)
    },

    refresh() {
      let $this = this
      this.loading = true;
      this.toggleAllRealEstates(false);
      this.getAllRealEstates({
        page: $this.page.currentPage,
        amount: $this.page.amount,
        filter: $this.filter,
        sortcolumn: 'updatedDate',
        sortby: $this.sort.direction,
      })
          .then(this.onRealEstatesRetrieve, (error) => $this.error = error)


      this.getAllBuildings({
        page: 1,
        amount: 9999,
        filter: $this.buildingFilter,
        sortcolumn: 'updatedDate',
        sortby: 'desc',
      }).then(this.onBuildingsRetrieve, (error) => $this.error = error)


      this.getExcelTemplateUrl().then((url) => $this.excelUrl = url);

    },

    onBuildingsRetrieve(data) {
      this.buildings = data["Buildings"];
    },

    onRealEstatesRetrieve(data) {
      this.page = {
        amount: data['Amount'],
        total: data['Total'],
        currentPage: data['Page']
      };
      this.sort = {
        by: data["sortColumn"],
        direction: data["sortOrder"]
      }
      this.realEstates = data["RealEstates"];
      this.loading = false;
    },
    onRealEstateSelect(id) {
      this.selected = id
      if (id === 0) {
        this.dialogs.realEstate = true
      }
    },
    copyToNextExpenses() {
      let $this = this;
      this.copyToNextExpensesRealEstate()
          .then(() => {
            $this.refresh()
          })
    },
    countDoneItems(todo) {
      if (todo == null) return "0/0 jobs";
      const doneItems = todo.filter((item) => item.done === true);
      const totalItems = todo.length;
      return `${doneItems.length}/${totalItems} jobs`;
    },

    onSort(data) {
      this.sort.by = data.sortBy;
      this.sort.direction = data.sortDesc ? "desc" : "asc";
      this.refresh();
    },
    onRowClick(realEstate) {
      window.open(`/real_estate/edit/${realEstate.id}`, '_blank');
    },
    submitRealEstate() {
      this.$root.$emit('crm::submitRealEstate')
    },
    onSubmitted(server) {
      console.log("RealEstate -> onSubmitted()", server)
      if (server.id != null)
        this.selected = server.id;
      this.refresh()
      this.dialogs.realEstate = false;
    }
  }
}
</script>

<style lang="scss">
.features-group .btn-group-toggle {
  width: 100%;

  i {
    font-size: 26px;
  }

  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px dashed #f69c55;
  }
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3); /* Semi-transparent white background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Adjust the z-index as needed */
}

.loading-spinner {
  text-align: center;
}


/*.features-group .btn {
  width: 100%;
  height: 100px;
  text-align: center;
  vertical-align: middle;
  //color:  !important;
}*/
</style>
